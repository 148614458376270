import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import divider from 'assets/images/Line_topic.png';

import Group24 from '../../assets/images/topics/group-24.inline.svg';
import banner from '../../assets/images/topics/Workforce.jpg';
import Group27 from '../../assets/images/topics/group-27.inline.svg';
import Asset from '../../assets/images/topics/Asset.inline.svg';
import CTATopic from '../../components/CTATopic';

const Workforce = () => {
  return (
    <Layout>
      <Helmet>
        <title>EY - The enlightened session</title>
      </Helmet>
      <div className='topicTemplate'>
        <Container>
          <div className='topicIntro'>
            <div className='banner'>
              <img src={banner} alt='Workforce Banner' />
            </div>
            <div className='text'>
              <Row>
                <Col>
                  <h1>Workforce</h1>

                  <div className='introText'>
                    <p>
                      Operating in a sector that has long been perceived as
                      staid, energy companies have struggled to shrug off their
                      image and appeal to young digital talent. Courtesy of the
                      global pandemic there has been a major recalibration of
                      how work gets done, which is contributing to an image
                      change for the energy sector.{' '}
                    </p>
                    <p>
                      Greater digital capacity and capabilities are elevating
                      ways of working. The need for flexibility has never been
                      greater. Not only are existing employees gaining new
                      skillsets but new talent is eyeing traditional utilities
                      through an entirely different lens. For energy companies,
                      digital is proving the means to unlock and maximize the
                      value of people and to create a better future for the
                      current and next gen. workforce.
                    </p>
                  </div>
                </Col>
                <Col />
              </Row>
            </div>
          </div>
          <div className='divider'>
            <img src={divider} alt='divider' />
          </div>
          <div>
            <div className='followSoon'>
              <h2>
                - Keynote speakers will <span>follow soon</span> -
              </h2>
            </div>
          </div>
          <div className='topicExpect'>
            <h1>
              What you <span>can expect?</span>
            </h1>
            <div>
              <p>
                TWith the shift towards digitization underway, this enlightened
                session will help with the challenges facing your workforce in
                adapting to new ways of working. Our keynote speakers will share
                real-life examples of how successful companies unlock their true
                potential when they put humans at the center of everything they
                do.
              </p>
            </div>
            <div className='expectWrapper'>
              <div className='expectItem'>
                <Asset className='asset' />
                <h6>The new age</h6>
                <p>
                  Digital – and the pandemic – are forcing us to rethink the way
                  we work.Find out how to transform with trust and confidence
                  and to collaborate with peers and competitors.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group24 />
                <h6>Keynote session</h6>
                <p>
                  Our keynote speakers will inspire you with their refreshing
                  vision of the future of the workforce. Learn what skills are
                  needed for your business to thrive and ways to attract and
                  retain the best people.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group27 />
                <h6>Interactive discussion</h6>
                <p>
                  In this interactive session, we focus on your workforce. We
                  will explore with you ways to put humans at the center of your
                  organization and prepare your people for the new era of
                  energy.
                </p>
                <p>
                  <em>Limited audience</em>
                </p>
              </div>
            </div>
          </div>
          <CTATopic
            title={'Workforce'}
            description={
              'Join in this unique experience. Sign up for this exclusive enlightened session and maximize the potential of your workforce.'
            }
          />
        </Container>
      </div>
    </Layout>
  );
};
export default Workforce;
